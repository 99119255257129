
import React, { Component,useState,Redirect,useEffect } from 'react'
import { Link,Navigate,useNavigate  } from 'react-router-dom';
import axios from '../../api/axios';


const Forgot_password = () => {
   
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const [email,setEmail]=useState('');
  const [name,setName]=useState('');
  const [pass,setPass]=useState('');
  const [reTypePass,setReTypePass]=useState();
  const[phoneNumber,setPhoneNumber]=useState();
  const [errMsgApi, setErrMsgApi] = useState('');
  const [success, setSuccess] = useState(false);
  const[errorMessage,setErrorMessage]=useState('');
  const[redirect,setRedirect]=useState(true);
  const navigate = useNavigate();
  const [otpVarified, setOtpVerified] = useState(false);
  const [successReg, setSuccessReg] = useState(false);
  const [newPassword,setNewPassword]=useState('');
  const [forgotVerifyToken,setforgotVerifyToken]=useState('');
  
  
//http://127.0.0.1/carqadmin/public/api/user/forgot/validate
  const[otp,setOtp]=useState('');
  const FORGOT_URI='forgot';
  const VERIFY_ME='forgot/validate';
  const NEW_PASS_URI='newpassword';
  useEffect(() => {
    if (forgotVerifyToken) {
      console.log("Token Set:", forgotVerifyToken);
    }
  }, [forgotVerifyToken]);
  const divStyle={
    display:'block',
  };
  const handleSubmitNewPassword=async(e)=>{
    e.preventDefault();
   // alert(forgotVerifyToken);
    try{
        const response=await axios.post(NEW_PASS_URI,JSON.stringify({
            password:newPassword
          }),{
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer '+forgotVerifyToken
            }
          });
          if(response.data.success==true){
             navigate("/");
          }

    }catch(err){
        setErrMsgApi('Invalid Data');
      }

  }
  const handleSubmitOtp=async(e)=>{
    e.preventDefault();
    try{
      const response=await axios.post(VERIFY_ME,JSON.stringify({
        phone_no:phoneNumber,otp:otp,'type':'0'
      }),{
        headers:{'Content-Type':'application/json'}
      });
    //  alert();
    if(response.data.success==true)
    {
        console.log(response.data);
     // navigate("/");
     // window.location.reload();
     //console.log("Response token is "+response.data.data.token);
     var token=response.data.data.token;
     setforgotVerifyToken(token); 
     
     console.log("tokenSet At var"+forgotVerifyToken+"end");
     //alert(forgotVerifyToken);
     setOtpVerified(true);
     setSuccessReg(false);
    //  return <Navigate to="/"/>
      //setOtpSuccess(true);
    }
    if(response.data.success==0)
    {
       
      //return <Navigate to="/"/>
     // navigate("/");
     // window.location.reload();
      setErrMsgApi(response.data.msg);
      
    }

    
    }
    catch(err){
      setErrMsgApi('Invalid OTP submission');
    }
    return <Navigate to="/"/> 
  }
  const handleSubmit=async (e)=>{
    if(pass!==reTypePass)
    {
      setErrorMessage('Passwords do not match');
    }
    else{
      setErrorMessage('');
    }
    e.preventDefault();
    if(errorMessage=='')
    {
      try{
        const response=await axios.post(FORGOT_URI,JSON.stringify({
          phone_no:phoneNumber,'type':'0'
        }),{
          headers:{'Content-Type':'application/json'}
        });
        console.log("success");
        //setErrMsgApi('Please check email and phone numbers');
        console.log(response.data);
        setSuccess(true);
        setSuccessReg(true);
      }catch(err){
        setErrMsgApi('Please check email and phone numberd! Minimum password length is 6');
      }
    }
    
    //setSuccess(true);

   // console.log(email +'--'+ pass);
  }
  
  return (
   
<body className="register-page">
<div className="register-box">
  <div className="register-logo">
    
    
  </div>
  <div className="card card-dark">
    <div className='card-header text-center'>
    <img src="../../dist/img/blue.png" alt="AdminLTE Logo"  className="brand-image" style={{opacity: '1',maxHeight:'42px'}} /><br></br>
    </div>
    <div className="card-body register-card-body">
    
      { errMsgApi!='' ?
        <div class="alert alert-danger alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
        <h5><i class="icon fas fa-exclamation-triangle"></i> Error!</h5>
        {errMsgApi}
        </div> : ''
      }
    
      <p className="login-box-msg">Recover password </p>
      {otpVarified ? <div>
        <form onSubmit={handleSubmitNewPassword}  method="post">
     <div className="input-group mb-3">
          <input type="text" className="form-control" value={newPassword} required  onChange={(e)=>setNewPassword(e.target.value)} placeholder="Please enter new password" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user" />
            </div>
          </div>
        </div>
        <button className='btn btn-primary btn-md'>Submit New password</button>
  </form>
      </div> : <div></div>}

      {successReg ? <div>
        <form onSubmit={handleSubmitOtp}  method="post">
     <div className="input-group mb-3">
          <input type="number" className="form-control" value={otp} required  onChange={(e)=>setOtp(e.target.value)} placeholder="Please enter otp" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user" />
            </div>
          </div>
        </div>
        <button className='btn btn-primary btn-md'>Submit OTP</button>
  </form>   
      </div> : <div></div>}
      <>
      
      {success==false ? (
  <form onSubmit={handleSubmit}  method="post">
       
  <div className="input-group mb-3">
    <input type="number" className="form-control"  onChange={(e)=>setPhoneNumber(e.target.value)} placeholder="Phone Number" />
    <div className="input-group-append">
      <div className="input-group-text">
        <span className="fas fa-phone" />
      </div>
    </div>
  </div>
 
  <div className="row">
    <div className="col-8">
      <div className="icheck-primary">
        {/* <input type="checkbox" id="agreeTerms" name="terms" defaultValue="agree" />
        <label htmlFor="agreeTerms">
          I agree to the <a href="#">terms</a>
        </label> */}
      </div>
    </div>
    {/* /.col */}
    <div className="col-4">
      <button type="submit" className="btn btn-primary btn-block">Get OTP</button>
    </div>
    {/* /.col */}
  </div>
</form>     
      ):( 
  <div></div>
      )}
      
      </>
      <Link to="/">I already have a membership</Link>
      
    </div>
    {/* /.form-box */}
  </div>{/* /.card */}
</div>




</body>
  )
}
export default Forgot_password
